import type { ComponentType } from "react"
import { useEffect } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import { useAccount, useDisconnect, useConnect } from "wagmi"

// Learn more: https://www.framer.com/docs/guides/overrides/

import { useWeb3Modal } from "@web3modal/wagmi/react"

export function withWalletConnect(Component): ComponentType {
    return (props) => {
        const { open } = useWeb3Modal()
        const { connect } = useConnect()
        const { address, isConnecting, isDisconnected } = useAccount()
        return (
            !address && (
                <Component
                    {...props}
                    onClick={() => {
                        open({ view: "Connect" })
                    }}
                />
            )
        )
    }
}

export function withWalletAddressButton(Component): ComponentType {
    return (props) => {
        const { open } = useWeb3Modal()
        const { disconnect } = useDisconnect()
        const { address, isConnecting, isDisconnected } = useAccount()

        return (
            address && (
                <Component
                    {...props}
                    onClick={() => {
                        disconnect()
                    }}
                />
            )
        )
    }
}

export function withWalletAddressText(Component): ComponentType {
    return (props) => {
        const { address, isConnecting, isDisconnected } = useAccount()
        return <Component {...props} text={address} />
    }
}
